export default class Header {
  constructor() {
    $(".js-headerHamburger").on("click", (e) => {
      if ($(e.currentTarget).hasClass("active")) {
        this.closeMenu();
      } else {
        this.openMenu();
      }
    });
  }

  openMenu() {
    $(".js-headerHamburger").addClass("active");
    $(".js-menu").addClass("active");
  }

  closeMenu() {
    $(".js-headerHamburger").removeClass("active");
    $(".js-menu").removeClass("active");
  }
}
