import L from "leaflet";

export default class Maps {
  constructor() {
    if ($(".js-map").length > 0) {
      this.lastClick = 0;
      this.markerArray = [];
      this.access = {
        maxZoom: 18,
        tileSize: 512,
        detectRetina: true,
        zoomOffset: -1,
      };
      this.icon = L.divIcon({
        html: '<svg viewBox="0 0 1000 914.22" xmlns="http://www.w3.org/2000/svg"><path fill="#ff6400" stroke="#000" d="m500 914.22 414.21-414.22c114.39-114.39 114.39-299.84 0-414.22s-299.82-114.38-414.21.01c-114.38-114.38-299.84-114.39-414.22-.01s-114.37 299.85 0 414.22z"/></svg>',
        iconSize: [22, 20],
        iconAnchor: [11, 22],
      });

      this.initMap();
      this.footerClick();
    }
  }

  footerClick() {
    $(".js-footerLink").on("click", (e) => {
      if ($(".js-map").hasClass("active")) {
        this.closeMap();
      } else {
        this.openMap();
      }
    });
  }

  openMap() {
    $(".js-header").addClass("no-bg");
    $(".js-map").animate({ opacity: 1 }, 300, $.bez([0.65, 0.05, 0.36, 1]), () => {
      $(".js-map").addClass("active");
    });
  }

  closeMap() {
    $(".js-map").animate({ opacity: 0 }, 300, $.bez([0.65, 0.05, 0.36, 1]), () => {
      $(".js-map").removeClass("active").removeAttr("style");
      $(".js-header").removeClass("no-bg");
    });
  }

  initMap() {
    $(".js-map")
      .find(".js-mapItem")
      .each((index, element) => {
        this.addToMarkerArray(element);
      });

    const map = L.map($(".js-map")[0]);
    const groupOfMarkers = L.featureGroup(this.markerArray).addTo(map);
    map.fitBounds(groupOfMarkers.getBounds(), { maxZoom: 15 });
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", Maps.access).addTo(map);
  }

  addToMarkerArray(element) {
    const itemLat = parseFloat($(element).data("latitude"));
    const itemLong = parseFloat($(element).data("longitude"));
    const marker = L.marker([itemLat, itemLong], {
      icon: this.icon,
    });

    marker.on("click", (e) => {
      if (this.lastClick <= Date.now() - 100) {
        var url = `https://goo.gl/maps/9AmHMyfsX9kPLwZp8`;
        window.open(url, "_blank");
        this.lastClick = Date.now();
      }
    });

    this.markerArray.push(marker);
  }
}
