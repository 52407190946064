import swipe from "jquery-touchswipe";
import bez from "jquery-bez";
import gsap from "gsap";

export default class Slideshow {
  constructor() {
    this.wait = false;

    this.openSlideshow();
    this.clickSlideshow();
    this.moveInSlideshow();

    this.initKeyDown();
    this.initSwipe();
  }

  openSlideshow() {
    $(".js-openSlideshow").on("click", (e) => {
      const index = $(e.currentTarget).data("slideshow");
      $(".js-header").addClass("no-bg");
      $('.js-slideshow[data-slideshow="' + index + '"]').animate({ opacity: 1 }, 300, $.bez([0.65, 0.05, 0.36, 1]), function () {
        $('.js-slideshow[data-slideshow="' + index + '"]').addClass("active");
      });
    });
  }

  closeSlideshow(slideshow) {
    $(slideshow).animate({ opacity: 0 }, 300, $.bez([0.65, 0.05, 0.36, 1]), () => {
      $(slideshow).removeClass("active").removeAttr("style");
      $(slideshow).find(".js-slide").removeClass("active").removeAttr("style").first().addClass("active");
      $(".js-header").removeClass("no-bg");
    });
  }

  clickSlideshow() {
    $(".js-slideshow").on("click", (e) => {
      const percentage = e.pageX / $(e.currentTarget).width();
      const x = Math.round(percentage);
      if (x) {
        this.next(e.currentTarget);
      } else {
        this.previous(e.currentTarget);
      }
    });
  }

  moveInSlideshow() {
    $(".js-slideshow").on("mousemove", (e) => {
      const percentage = e.pageX / $(e.currentTarget).width();
      const x = Math.round(percentage);
      if (x) {
        $(e.currentTarget).removeClass("w-cursor");
      } else {
        $(e.currentTarget).addClass("w-cursor");
      }
    });
  }

  initKeyDown() {
    $(window)
      .off("keydown")
      .on("keydown", (e) => {
        const slideshow = $(".js-slideshow").hasClass("active") ? $(".js-slideshow.active") : $(".js-slideshow");
        if (e.code == "ArrowRight" || e.code == "ArrowDown") {
          this.next($(slideshow));
        } else if (e.code == "ArrowLeft" || e.code == "ArrowUp") {
          this.previous($(slideshow));
        }
      });
  }

  initSwipe() {
    $(".js-slideshow").swipe({
      swipe: (event, direction) => {
        const slideshow = $(".js-slideshow").hasClass("active") ? $(".js-slideshow.active") : $(".js-slideshow");
        if (direction == "left") {
          this.next($(slideshow));
        } else if (direction == "right") {
          this.prev($(slideshow));
        }
      },
    });
  }

  next(slideshow) {
    const el = $(slideshow).find(".js-slide.active");
    const i = $(slideshow).find(".js-slide").index(el);
    const length = $(slideshow).find(".js-slide").length;
    const nextEl = i > length - 2 ? $(slideshow).find(".js-slide").first() : $(el).next();
    if ($(slideshow).hasClass("js-slideshowAbout") && i > length - 2) {
      this.closeSlideshow(slideshow);
    } else {
      this.change(nextEl, slideshow);
    }
  }

  previous(slideshow) {
    const el = $(slideshow).find(".js-slide.active");
    const i = $(slideshow).find(".js-slide").index(el);
    const prevEl = i < 1 ? $(slideshow).find(".js-slide").last() : $(el).prev();
    if ($(slideshow).hasClass("js-slideshowAbout") && i < 1) {
      this.closeSlideshow(slideshow);
    } else {
      this.change(prevEl, slideshow);
    }
  }

  change(element, slideshow) {
    const timing = 600;

    if (!this.wait) {
      const current = $(slideshow).find(".js-slide.active");
      const el = $(element) != undefined ? $(element) : $(slideshow).find(".js-slide").first();
      const i = $(slideshow).find(".js-slide").index(el) + 1;
      const num = i;

      $(el).addClass("activing");

      var tl = gsap.timeline({
        onComplete: function () {
          $(current).removeClass("active").removeAttr("style");
          $(el).removeClass("activing").addClass("active");
        },
      });
      tl.to($(current), { "--gradientStart": -100, duration: (timing - 200) / 1000, ease: "Power3.easeInOut" });
      tl.to($(current), { "--gradientEnd": 0, duration: timing / 1000, ease: "Power3.easeInOut" }, "<");

      $(slideshow).parents(".js-resident").find(".js-residentCounter").text(num);

      this.wait = true;

      setTimeout(() => {
        this.wait = false;
      }, timing + 20);
    }
  }
}
