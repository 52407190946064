import bez from "jquery-bez";

export default class Residents {
  constructor() {
    $(".js-residentsTitle").on("click", (e) => {
      const list = $(e.currentTarget).next(".js-residentsList");
      if ($(list).hasClass("active")) {
        this.closeList(list);
      } else {
        this.openList(list);
      }
    });
  }

  openList(list) {
    $(".js-residentsList").not(list).slideUp(300).removeClass("active");
    $(list)
      .slideDown(300, $.bez([0.65, 0.05, 0.36, 1]))
      .addClass("active");
  }

  closeList(list) {
    $(list)
      .slideUp(300, $.bez([0.65, 0.05, 0.36, 1]))
      .removeClass("active");
  }
}
