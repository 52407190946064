export default class News {
  constructor() {
    this.sizeContainer();
    this.observer();

    $(window).on("resize", () => {
      setTimeout(() => {
        this.sizeContainer();
      }, 200);
    });

    $(window).on("scroll", () => {
      this.setExclusion();
    });
  }

  setExclusion() {
    if (window.matchMedia("(min-width: 576px)").matches) {
      $(".js-newsContainer").each(function (i, el) {
        const text = $(el).find(".js-newsText");
        const image = $(el).find(".js-newsImage");
        if ($(image).length > 0) {
          const height = $(text).outerHeight();
          $(el).find(".js-newsExclusion").css({ height: height });
          let bottom = $(image).offset().top - $(text).offset().top - height;
          if (bottom < 0) {
            const imageHeight = $(image).outerHeight();
            let bottomPercentage = height + bottom;
            if (bottomPercentage < 0) bottomPercentage = 0;
            if (Math.round(height - bottomPercentage) < Math.round(imageHeight) - 1) {
              $(el)
                .find(".js-newsExclusion")
                .css({ height: height, "--bottom-percentage": bottomPercentage + "px" });
            }
          } else {
            $(el).find(".js-newsExclusion").css({ height: 0, "--bottom-percentage": "100%" });
          }
        }
      });
    }
  }

  sizeContainer() {
    if (window.matchMedia("(min-width: 576px)").matches && $(".js-newsContainer").length > 0) {
      $("html, body").css({ overflow: "hidden" });
      $(".js-newsContainer").each((i, el) => {
        const text = $(el).find(".js-newsText");
        const image = $(el).find(".js-newsImage");
        $(el).find(".js-newsExclusion").css({ height: 0, "--bottom-percentage": "100%" });
        const height = $(text).outerHeight() + $(image).outerHeight() + 10;
        setTimeout(() => {
          $(el).css({ height: height }).addClass("active");
          $("html, body").css({ overflow: "" });
          this.setExclusion();
        }, 50);
      });
    } else {
      $(".js-newsContainer").each((i, el) => {
        $(el).css({ height: "" });
      });
    }
  }

  observer() {
    const imageObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const image = $(entry.target).find("img");
            $(image).addClass("loaded");
          }
        });
      },
      {
        rootMargin: "400px 0px 400px 0px",
      }
    );

    $(".js-newsImage").each((i, el) => {
      imageObserver.observe($(el)[0]);
    });
  }
}
