import bez from "jquery-bez";

export default class Logotype {
  constructor() {
    this.clickLogotype();
    this.hoverLogotype();
  }

  clickLogotype() {
    $(".js-logotypePath").on("click", function () {
      $(".js-logotype").addClass("small");
      setTimeout(() => {
        $(".js-header")
          .css({ display: "flex" })
          .animate({ opacity: 1 }, 300, $.bez([0.65, 0.05, 0.36, 1]));
        $(".js-homeQuote")
          .css({ display: "grid" })
          .animate({ opacity: 1 }, 300, $.bez([0.65, 0.05, 0.36, 1]), () => {
            $(".js-logotype").empty();
          });
      }, 600);
    });
  }

  hoverLogotype() {
    $(".js-logotypePath")
      .on("mouseenter", function () {
        $(".js-logotype").addClass("hover");
      })
      .on("mouseleave", function () {
        $(".js-logotype").removeClass("hover");
      });
  }
}
