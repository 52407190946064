import "./_lib/modernizr";
import "../scss/application.scss";
import jquery from "jquery";

window.$ = window.jQuery = jquery;

import Website from "./_modules/Website";
import Maps from "./_modules/Maps";
import Logotype from "./_modules/Logotype";
import Slideshow from "./_modules/Slideshow";
import News from "./_modules/News";
import Residents from "./_modules/Residents";
import Header from "./_modules/Header";

$(function () {
  new Website();
  new Header();
  new Maps();
  new Logotype();
  new Slideshow();
  new News();
  new Residents();
});
